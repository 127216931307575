:root {
  --pinkcookies: #f06ba8;
  --blueberry: #7accc8;
  --creamy: #fffef0;
  --brownie: #2a1a10;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  text-align: center;
}

h1 {
  font-family: 'Euphoria Script', cursive;
  font-weight: bolder;
  font-size: 3.5em;
}

.rounded-50 {
  border-radius: 50%;
}

.social {
  float: right;
  margin-right: 0.3em;
}

.social-text {
  visibility: hidden;
  float: right;
  margin-right: 0.3em;
}

.btn-blueberry {
  background-color: var(--blueberry);
}

.btn-brownie {
  background-color: var(--brownie);
}

.btn-brownie:hover {
  background-color: var(--brownie);
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.9);
}

/* OVERRIDE BOOTSTRAP */
.bg-dark {
  background-color: var(--brownie) !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: var(--creamy);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--brownie);
}
.btn {
  color: white;
}

a {
  color: var(--blueberry);
}

a:hover {
  color: var(--pinkcookies);
}

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  /* transition: .5s ease-in-out; */
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing h3 {
  display: inline;
  font-weight: 200;
  text-decoration: underline;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 3rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

/* PRICES */
.prev-price {
  text-decoration: line-through;
  opacity: 0.4;
  font-weight: 300;
}
.current-price {
  font-size: 1.15em;
  color: var(--pinkcookies);
}
.specials,
.contact,
.specialty,
.price-list {
  cursor: pointer;
}

/* TEXT LAYOUT */
.full-menu-body {
  line-height: 1.5em;
}
.bio, .thank-you {
  line-height: 2em;
}

/* Links */
.cta-links {
  color: rgb(108, 117, 125);
}
.cta-links:hover {
  text-decoration: none;
  color: rgb(84, 90, 97);
}

/* FULL-MENU */
.drip {
  position: relative;
  top: 0px;
  opacity: 1;
  z-index: -1;
}
.drip-over {
  z-index: 10;
}

.full-menu-body,
.meet-sam-body,
.order-body,
.gallery-body,
.thank-you-body {
  position: relative;
  top: -250px;
}
.full-menu-description {
  line-height: 1.75em;
  font-size: 1em;
}
.full-menu-title {
  font-size: 2em;
  color: var(--brownie);
  margin-bottom: 0.25em;
}
.full-menu-price {
  font-size: 1.1em;
}

#treat,
#specialty {
  margin-top: 1.75em;
  color: var(--pinkcookies);
  font-size: 2.75em;
}

#sweet {
  color: var(--pinkcookies);
  font-size: 2.75em;
}

/* TESTIMONIAL */
.testimonial > .carousel-item {
  height: 16rem;
}
.testimonial .carousel-caption > p {
  color: var(--blueberry);
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading,
  .best_sellers-heading,
  .testimonial-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 3.5em;
  }
  .drip {
    top: 3px;
  }
  .full-menu-body,
  .meet-sam-body,
  .order-body,
  .gallery-body {
    top: 0px;
  }
  .best-sellers {
    display: none;
  }
  .social {
    display: none;
  }
  .social-text {
    float: left;
    visibility: visible;
  }
}
